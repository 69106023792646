import { Chart, ReactGoogleChartProps } from "react-google-charts";
import { commonAxisOption, CommonChartParams, getLegendConfig, getOnClickEvent } from "../config";
import { NoData } from "../shared/NoData";

export enum ORIENTATION {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal"
}

export type BarChartParams = CommonChartParams & {
    titles: string[]; // title under each bar
    colors?: string[];
    orientation?: ORIENTATION;
    xTitle?: string;
    yTitle?: string;
    xMaxValue?: number;
    yMaxValue?: number;
    isStacked?: boolean;
    onClick?: (selectedItem: { datasetIndex: number; dataIndex: number }) => void;
};

/**
 * Build data for multiple series (stacked or multiple bars) with tooltip
 *
 * Example:
 *
 * [
 *     [
 *         "key",
 *         "Score",
 *         {
 *             "role": "tooltip",
 *             "type": "string",
 *             "p": {
 *                 "html": true,
 *                 "role": "tooltip"
 *             }
 *         },
 *         "Industry Reference",
 *         {
 *             "role": "tooltip",
 *             "type": "string",
 *             "p": {
 *                 "html": true,
 *                 "role": "tooltip"
 *             }
 *         }
 *     ],
 *     [
 *         "Compliance",
 *         50,
 *         "OPTIONAL TOOLTIP HERE",
 *         27,
 *         "OPTIONAL TOOLTIP HERE"
 *     ]
 * ]
 */
function buildDataForMultipleSeries(
    dataset: CommonChartParams["dataset"],
    titles: string[],
    hasMultipleBar?: boolean,
    tooltip?: (item: { label: string; values: (string | number)[] }, index: number) => string
): ReactGoogleChartProps["data"] {
    return [
        [
            "key",
            ...(!hasMultipleBar ? [{ role: "style" }] : []),
            ...dataset.flatMap((data) => [data.label, ...(tooltip ? [{ role: "tooltip", type: "string", p: { html: true } }] : [])])
        ],
        ...titles.map((title, i) => [
            title,
            ...dataset.flatMap(data => [
                data.data[i],
                ...(tooltip
                    ? [
                          tooltip(
                              {
                                  label: title,
                                  values: [data.data[i]]
                              },
                              i
                          )
                      ]
                    : [])
            ])
        ])
    ];
}

/**
 * Build data for standard series (not stacked and not multiple bars)
 *
 * Example:
 *
 * [
 *     [
 *         "key",
 *         "A",
 *         { "role": "style" },
 *         {
 *             "role": "tooltip",
 *             "type": "string",
 *             "p": {
 *                 "html": true,
 *                 "role": "tooltip"
 *             }
 *         }
 *     ],
 *     [
 *         "A",
 *         50,
 *         "#11C58F",
 *         "OPTIONAL TOOLTIP HERE"
 *     ]
 * ]
 */
function buildDataForStandardSeries(
    dataset: CommonChartParams["dataset"],
    titles: string[],
    colors?: string[],
    hasMultipleBar?: boolean,
    tooltip?: (item: { label: string; values: (string | number)[] }, index: number) => string
): ReactGoogleChartProps["data"] {
    return [
        [
            "key",
            ...dataset.map(data => data.label),
            ...(!hasMultipleBar ? [{ role: "style" }] : []),
            ...(tooltip ? [{ role: "tooltip", type: "string", p: { html: true } }] : [])
        ],
        ...titles.map((title, i) => [
            title,
            ...dataset.map(data => data.data[i]),
            ...(!hasMultipleBar ? dataset.map(() => (colors ? colors[i] : null)) : []),
            ...(tooltip
                ? [
                      tooltip(
                          {
                              label: title,
                              values: dataset.map(data => data.data[i])
                          },
                          i
                      )
                  ]
                : [])
        ])
    ];
}

export function BarChart({
    titles,
    dataset,
    colors,
    orientation = ORIENTATION.VERTICAL,
    xTitle,
    yTitle,
    width = "100%",
    height = "auto",
    isStacked = false,
    legendPosition = "top",
    xMaxValue,
    yMaxValue,
    tooltip,
    onClick
}: BarChartParams): JSX.Element {
    if (!dataset.length) {
        return <NoData width={width} height={height} />;
    }
    const hasMultipleBar = dataset.length > 1;
    return (
        <Chart
            chartType={orientation === ORIENTATION.VERTICAL ? "ColumnChart" : "BarChart"}
            width={width}
            height={height}
            options={{
                legend: getLegendConfig(legendPosition, "center", { fontSize: 14, margin: 15 }),
                vAxis: {
                    title: yTitle,
                    ...(yMaxValue
                        ? {
                              viewWindowMode: "explicit",
                              viewWindow: {
                                  max: yMaxValue,
                                  min: 0
                              }
                          }
                        : {
                              minValue: 5
                          }),
                    ...commonAxisOption
                },
                hAxis: {
                    title: xTitle,
                    ...(xMaxValue
                        ? {
                              viewWindowMode: "explicit",
                              viewWindow: {
                                  max: xMaxValue,
                                  min: 0
                              }
                          }
                        : {
                              minValue: 5
                          }),
                    ...commonAxisOption
                },
                bar: { groupWidth: orientation === ORIENTATION.VERTICAL ? "90%" : "70%" },
                isStacked,
                colors,
                tooltip: {
                    isHtml: true
                }
            }}
            data={
                isStacked || hasMultipleBar
                    ? buildDataForMultipleSeries(dataset, titles, hasMultipleBar, tooltip)
                    : buildDataForStandardSeries(dataset, titles, colors, hasMultipleBar, tooltip)
            }
            chartEvents={
                onClick ? [getOnClickEvent(({ row: dataIndex = 0, column: datasetIndex = 0 }) => onClick({ dataIndex, datasetIndex: datasetIndex - 1 }))] : []
            }
            legendToggle
        />
    );
}
