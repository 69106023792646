import { Account, AccountRights, AccountRole, EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { Controller, Form, Input, Label, Modal, openNotificationWithIcon, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import AccountUserRightsSelector from "../../../components/AccountUserRightsSelector";
import { AccountLang } from "../../../lang/AccountLang";
import { sendAccountInvitation } from "../../../services/accountInvitationService";
import { getAccountRoleRights } from "../../../services/accountService";

const InviteUserDialog = ({
    isLoggedUserAdmin,
    account,
    accountUsersEmails,
    invitedUsersEmails,
    onClose
}: {
    isLoggedUserAdmin: boolean;
    account: Account;
    accountUsersEmails: string[];
    invitedUsersEmails: string[];
    onClose: () => void;
}): JSX.Element => {
    const [working, setWorking] = useState(false);
    const [rights, setRights] = useState<AccountRights[]>(getAccountRoleRights(AccountRole.CONTRIBUTOR));

    const lang = useLang<AccountLang>();

    const schema = yup.object({
        email: useRequiredString()
            .trim()
            .matches(EMAIL_REGEX, lang.shared.invalidEmail)
            .test(
                "email-not-in-account-yet",
                lang.accountSettings.userAccessView.inviteUser.errors.alreadyInAccountErrorMessage,
                value => !accountUsersEmails.includes(value)
            )
            .test(
                "email-not-invited-yet",
                lang.accountSettings.userAccessView.inviteUser.errors.alreadyInvitedErrorMessage,
                value => !invitedUsersEmails.includes(value)
            )
    });

    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<{ email: string; role: string }>({
        schema,
        mode: "onChange"
    });

    async function sendInvite({ email }: { email: string }) {
        try {
            setWorking(true);
            await sendAccountInvitation(account, email, rights);
            onClose();
            openNotificationWithIcon({ type: "success", description: lang.shared.inviteSuccessMessage });
        } catch (err) {
            openNotificationWithIcon({ type: "error", description: lang.shared.inviteErrorMessage });
        } finally {
            setWorking(false);
        }
    }

    return (
        <Modal
            size="md"
            title={lang.accountSettings.userAccessView.inviteUser.name}
            isOpen
            onConfirm={handleSubmit(sendInvite)}
            onClose={onClose}
            isLoading={working}
            okText={lang.accountSettings.userAccessView.sendInvite}
            lang={lang}
            isDisabled={!isValid}
        >
            <Form onSubmit={handleSubmit(sendInvite)}>
                <Controller
                    name="email"
                    data-id="invite-email-control"
                    control={control}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            placeholder={`${lang.shared.email}...`}
                            autoFocus
                            disabled={working}
                            data-id="invite-email-input"
                            label={lang.accountSettings.userAccessView.inviteUser.emailPlaceholder}
                            errorMessage={errors.email?.message}
                            required
                        />
                    )}
                />
                <Controller
                    name="role"
                    control={control}
                    data-id="invite-role-control"
                    render={({ field }) => (
                        <>
                            <Label children={lang.accountSettings.userAccessView.inviteUser.rolePlaceholder} required />
                            <AccountUserRightsSelector
                                {...field}
                                isLoggedUserAdmin={isLoggedUserAdmin}
                                rights={rights}
                                onRightsChange={setRights}
                                isDisabled={working}
                            />
                        </>
                    )}
                />
            </Form>
        </Modal>
    );
};
export default InviteUserDialog;
