import { isDefined } from "@vaultinum/vaultinum-api";
import { get, isNumber, keyBy, mapValues, merge, uniq, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import {
    AddIcon,
    Button,
    Buttons,
    FilterProps,
    Input,
    SortDirection,
    SortProps,
    Table as TableComponent,
    TableProps,
    Tables,
    TreeTableProps,
    isNotNull,
    plural,
    useLang
} from "../../../../../common";
import { FilterController } from "../../../../controllers/filter.controller";

type CommonLayoutProps<T, U> = {
    render: ((list: T[]) => JSX.Element) | JSX.Element;
    filters?: FilterProps<T>[];
    filterController?: FilterController;
    sorts?: SortProps<T>[];
    totalCount?: number;
    searchNavigation?: {
        getMatchingItems: (list: T[], searchText: string) => U[];
        onCurrentMatchingItemChange: (item: U | undefined) => void;
    };
};

export type BaseLayoutProps<T, U> = CommonLayoutProps<T, U> & {
    searchProps?: {
        navigation: {
            onNext: () => void;
            onPrevious: () => void;
        };
    };
    searchText?: string;
    setSearchText: (text: string) => void;
    list?: T[];
    listFilteredByText?: T[];
    buttons?: JSX.Element;
    matchingItems?: U[];
    setMatchingItems?: React.Dispatch<React.SetStateAction<U[] | undefined>>;
    setCurrentItemIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
    resultsCount?: number;
};

export type ListLayoutProps<T, U> = CommonLayoutProps<T, U> & {
    list: T[];
    add?: {
        onAdd: () => void;
        label?: string;
    };
    searchableKeys?: (keyof T | `${string}.${string}`)[];
};

export namespace Layouts {
    export function Base<T, U>({
        setSearchText,
        searchProps,
        totalCount,
        filters,
        filterController,
        buttons,
        sorts,
        searchNavigation,
        searchText,
        list,
        listFilteredByText,
        render,
        matchingItems,
        setMatchingItems,
        setCurrentItemIndex,
        resultsCount
    }: BaseLayoutProps<T, U>): JSX.Element {
        const lang = useLang();
        const [value, setValue] = useState<string>("");
        const defaultOptions = filters?.reduce<Record<string, string[]>>((acc, filter) => {
            const filterOptions = keyBy(filter.filters, subFilter => subFilter.key);
            const mappedValues = mapValues(filterOptions, subFilter => subFilter.defaultOptions ?? []);
            return { ...acc, ...mappedValues };
        }, {});
        const initialFilters = merge(defaultOptions, filterController?.loadFilters()) ?? {};
        const [selectedSorts, setSelectedSorts] = useState<Record<string, SortDirection | false> | undefined>(
            filterController?.loadSorts() ?? undefined
        );
        const [selectedFilters, setSelectedFilters] = useState<Record<string, unknown[]>>(initialFilters);

        useEffect(() => {
            if (searchText) {
                setMatchingItems?.(searchNavigation?.getMatchingItems(listFilteredAndSorted ?? [], searchText));
                setCurrentItemIndex?.(0);
                searchNavigation?.onCurrentMatchingItemChange(matchingItems?.[0]);
            } else {
                setMatchingItems?.(undefined);
                setCurrentItemIndex?.(undefined);
                searchNavigation?.onCurrentMatchingItemChange(undefined);
            }
        }, [searchText]);

        function doSort(updatedSort: Record<string, SortDirection | false> | undefined): void {
            if (updatedSort) {
                setSelectedSorts(updatedSort);
                // only one sort for now
                const entries = Object.entries(updatedSort);
                if (entries.length) {
                    const [key, direction] = entries[0];
                    filterController?.saveSort(key, direction);
                }
            }
        }

        function applySorts(itemA: T, itemB: T): number {
            const currentSorts = Object.keys(selectedSorts ?? {});
            // only one sort for now
            if (!currentSorts.length) {
                return 0;
            }
            const selectedSort = sorts?.find(sort => sort.key === currentSorts[0]);
            if (!selectedSort) {
                return 0;
            }
            return selectedSort.onSort?.(itemA, itemB, selectedSorts?.[currentSorts[0]]) ?? 0;
        }

        function applyFilters(item: T): T | null {
            return Object.entries(selectedFilters).reduce((acc: T | null, [key, _value]: [string, unknown[]]) => {
                if (!acc || !_value?.length) {
                    return acc;
                }
                const selectedFilter = filters?.find(filter => filter.filters?.some(option => option.key === key));
                if (!selectedFilter?.onFilter) {
                    return acc;
                }
                return selectedFilter.onFilter(acc, _value as string[]);
            }, item);
        }

        function onFilterChange(filterKey: string, selectedOptions: (string | null)[]): void {
            setSelectedFilters(prev => ({
                ...prev,
                [filterKey]: selectedOptions
            }));
            filterController?.saveFilter(filterKey, selectedOptions);
        }

        const filtersWithOptions = filters?.map(filter => ({
            ...filter,
            filters: filter.filters.map(option => ({
                ...option,
                options: uniqBy(option.options, "value"),
                defaultOptions: uniq(selectedFilters[option.key] as string[]),
                onSubmit: (selectedOptions: string[]) => {
                    onFilterChange(option.key, selectedOptions);
                    option.onSubmit?.(selectedOptions);
                }
            }))
        }));

        const listFilteredAndSorted = (searchNavigation ? list : listFilteredByText)?.filter(isDefined).map(applyFilters).filter(isNotNull).sort(applySorts);

        const filteredCount =
            resultsCount ??
            (searchNavigation ? searchNavigation.getMatchingItems(listFilteredAndSorted ?? [], searchText ?? "").length : listFilteredAndSorted?.length);

        return (
            <div className="h-full">
                <div className="md:flex items-center justify-between space-y-2 sm:space-y-0 mb-4">
                    <div className="flex gap-2 items-center">
                        <Input.Search
                            className="w-72"
                            placeholder={lang.shared.search}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            debounce={{
                                wait: 300,
                                callback: e => {
                                    if (e.target.value.length) {
                                        setSearchText(e.target.value);
                                    } else {
                                        setSearchText("");
                                    }
                                }
                            }}
                            {...searchProps}
                        />
                        {isNumber(filteredCount) && (
                            <span className="text-slate-light font-semibold whitespace-nowrap">{plural(lang.shared.results, filteredCount, totalCount)}</span>
                        )}
                    </div>
                    <div className="flex justify-center md:justify-end gap-2">
                        {
                            <>
                                {filtersWithOptions?.map(({ label, filters: filterOptions, hasSearch = true, key }) => (
                                    <Buttons.Filters key={key} label={label} filters={filterOptions} hasSearch={hasSearch} />
                                ))}
                                {sorts && doSort && <Buttons.Sort sorts={sorts} onSubmit={doSort} defaultSort={selectedSorts} />}
                            </>
                        }
                        {buttons}
                    </div>
                </div>
                {typeof render === "function" ? render(listFilteredAndSorted ?? []) : render}
            </div>
        );
    }

    export function Table<T extends object, U = unknown, V = unknown>({
        columns,
        data,
        buttons,
        ...props
    }: TableProps<T, U, V> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>();
        const [totalCount, setTotalCount] = useState<number>();

        return (
            <Base
                setSearchText={setSearchText}
                buttons={buttons}
                resultsCount={resultsCount}
                totalCount={totalCount}
                render={
                    <TableComponent<T, U, V>
                        {...props}
                        columns={columns}
                        data={props.onLazyLoad ? [] : data ?? []}
                        searchText={searchText}
                        onFilter={(count, total) => {
                            setResultsCount(count);
                            setTotalCount(total);
                        }}
                    />
                }
            />
        );
    }

    export function TableTree<T extends { children?: T[] }>({ columns, data, buttons, ...props }: TreeTableProps<T> & { buttons?: JSX.Element }): JSX.Element {
        const [searchText, setSearchText] = useState<string>("");
        const [resultsCount, setResultsCount] = useState<number>(0);
        return (
            <Base
                setSearchText={setSearchText}
                resultsCount={resultsCount}
                totalCount={data?.length}
                buttons={buttons}
                render={<Tables.Tree columns={columns} data={data} searchText={searchText} onFilter={setResultsCount} {...props} />}
            />
        );
    }

    export function List<T, U>({
        add,
        searchNavigation,
        searchableKeys,
        filters,
        sorts,
        list,
        render,
        totalCount,
        filterController
    }: ListLayoutProps<T, U>): JSX.Element {
        const [matchingItems, setMatchingItems] = useState<U[]>();
        const [currentItemIndex, setCurrentItemIndex] = useState<number>();
        const [searchText, setSearchText] = useState<string>("");

        function onNext(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        function onPrevious(): void {
            if (!matchingItems?.length) {
                return;
            }
            const newIndex = ((currentItemIndex ?? 0) + matchingItems.length - 1) % matchingItems.length;
            setCurrentItemIndex(newIndex);
            searchNavigation?.onCurrentMatchingItemChange(matchingItems[newIndex]);
        }

        const listFilteredByText = list.filter(item => {
            if (searchableKeys?.length) {
                return searchableKeys.some(key => String(get(item, key))?.toLowerCase().includes(searchText.toLowerCase()));
            }
            return true;
        });

        return (
            <Base
                list={list}
                listFilteredByText={listFilteredByText}
                render={render}
                setSearchText={setSearchText}
                filters={filters}
                sorts={sorts}
                buttons={add && <Button children={add.label} icon={AddIcon} onClick={add.onAdd} data-id="btn-add-item" isLoading={false} />}
                setMatchingItems={setMatchingItems}
                setCurrentItemIndex={setCurrentItemIndex}
                matchingItems={matchingItems}
                totalCount={totalCount ?? list.length}
                filterController={filterController}
                {...(searchNavigation && matchingItems?.length && { searchProps: { navigation: { onNext, onPrevious } } })}
            />
        );
    }
}
