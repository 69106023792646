import classNames from "classnames";
import { ElementType } from "react";
import { Fill, Size, Type } from "../../referentials";
import { IconProps, LoadingIcon } from "../Icons";
import { Tooltip } from "../Tooltip/Tooltip";

export type ButtonProps = {
    isLoading: boolean;
    "data-id"?: string;
    icon?: ElementType;
    isDisabled?: boolean;
    fill?: Fill;
    type?: Extract<Type, "primary" | "default">;
    size?: Extract<Size, "sm" | "md">;
    rounded?: "left" | "right" | "full";
    htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    title?: string;
    iconPosition?: "left" | "right";
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "disabled" | "className">;

// Having the full classname placed here prevents the need to add these classnames to the tailwind "safelist"
const TYPE_FILL_STYLE: Record<NonNullable<ButtonProps["type"]>, Record<Fill, string>> = {
    primary: {
        solid: "text-white bg-slate-primary active:bg-slate-dark enabled:hover:bg-slate-light",
        link: "text-slate-primary underline active:text-slate-extra-light enabled:hover:text-slate-light"
    },
    default: {
        solid: "text-slate-primary shadow-inset shadow-slate-primary bg-white enabled:hover:shadow-slate-extra-light active:shadow-slate-extra-light active:text-slate-extra-light",
        link: "text-slate-primary underline active:text-slate-extra-light enabled:hover:text-slate-light"
    }
};

const SIZE_STYLE = {
    md: "p-1.5",
    sm: "p-1"
};

const ROUNDED_STYLE = {
    full: "rounded-md",
    left: "rounded-l-md",
    right: "rounded-r-md"
};

export const MODAL_BUTTON_STYLE = {
    cancelButtonProps: { className: `${TYPE_FILL_STYLE.default.solid} rounded uppercase` },
    okButtonProps: { className: `${TYPE_FILL_STYLE.primary.solid} rounded border-none uppercase` },
    disabled: { className: "cursor-not-allowed opacity-50" }
};

export function getIconProps(type: Type, fill: Fill, size: Size): IconProps {
    return {
        size: size === "md" ? "sm" : "xs",
        color: fill === "solid" && type === "primary" ? "white" : "slate"
    };
}

export function Button({
    icon: Icon,
    children,
    onClick,
    isLoading,
    isDisabled,
    title,
    fill = "solid",
    type = "primary",
    size = "md",
    rounded = "full",
    htmlType = "button",
    iconPosition = "left",
    ...props
}: ButtonProps) {
    const button = (
        <button
            disabled={isDisabled || isLoading}
            className={classNames(
                "flex h-max max-w-96 shrink-0 select-none items-center justify-center gap-2 text-xs font-semibold uppercase tracking-wider transition-colors",
                ROUNDED_STYLE[rounded],
                SIZE_STYLE[size],
                TYPE_FILL_STYLE[type][fill],
                {
                    "cursor-not-allowed opacity-50": isDisabled || isLoading,
                    "px-4": children
                }
            )}
            type={htmlType}
            {...props}
            {...(!isDisabled &&
                !isLoading && {
                    onClick
                })}
        >
            {isLoading && <LoadingIcon {...getIconProps(type, fill, size)} />}
            {!isLoading && Icon && iconPosition === "left" && <Icon {...getIconProps(type, fill, size)} />}
            {children}
            {!isLoading && Icon && iconPosition === "right" && <Icon {...getIconProps(type, fill, size)} />}
        </button>
    );

    if (title) {
        return <Tooltip title={title} children={button} asChild />;
    }
    return button;
}
