import classNames from "classnames";
import { ComponentProps, useState } from "react";
import { useLang } from "../../../../lang";
import { SortDirection } from "../../../../services";
import { DropdownActionProps, Dropdown as DropdownAlias, DropdownCommon, Filter, SortProps } from "../../Dropdown";
import { ChevronDownIcon } from "../../Icons";
import { Separator } from "../../Separator/Separator";
import { Tag } from "../../Tag";
import { Button, ButtonProps, getIconProps } from "../Button";

function Hoverable({ label, alwaysDisplay, buttonProps }: { buttonProps: ButtonProps; label: string; alwaysDisplay?: boolean }) {
    return (
        <div
            className={classNames("h-7 overflow-hidden rounded-md border group-hover:flex", {
                flex: alwaysDisplay,
                hidden: !alwaysDisplay
            })}
        >
            <Button {...buttonProps} type="default" data-id="btn-details" size="sm" isLoading={false}>
                <div className="!-ml-2 w-0 scale-x-0 transition-all duration-100 group-hover:!ml-0 group-hover:w-auto group-hover:scale-x-100">{label}</div>
            </Button>
        </div>
    );
}

function DropdownChevron({ type = "primary", fill = "solid", size = "md", isDisabled, rounded, iconPosition, children, isLoading }: ButtonProps) {
    return (
        <DropdownCommon.Trigger.Button
            type={type}
            fill={fill}
            size={size}
            isDisabled={isDisabled}
            rounded={rounded}
            iconPosition={iconPosition}
            children={children}
            icon={ChevronDownIcon}
            isLoading={isLoading}
        />
    );
}

function ClickableDropdownButton({ actions, type, fill, size, isDisabled, isLoading, ...props }: { actions: DropdownActionProps[] } & ButtonProps) {
    return (
        <>
            <Button {...props} type={type} fill={fill} size={size} isDisabled={isDisabled} isLoading={isLoading} rounded="left" />
            <Separator orientation="vertical" color="white" />
            <DropdownAlias>
                <DropdownChevron rounded="right" {...{ type, fill, size, isDisabled, isLoading }} />
                <DropdownCommon.Content.WithActions actions={actions} />
            </DropdownAlias>
        </>
    );
}

function DropdownButton({
    icon: ButtonIcon,
    iconPosition = "left",
    actions,
    type = "primary",
    fill = "solid",
    size = "md",
    isDisabled,
    isLoading,
    ...props
}: { actions: DropdownActionProps[] } & ButtonProps) {
    return (
        <DropdownAlias>
            <DropdownChevron {...{ type, fill, size, isDisabled, isLoading }} rounded="full" iconPosition="right">
                <div
                    className={classNames("flex items-center justify-center gap-2", {
                        "cursor-not-allowed opacity-50": isDisabled || isLoading
                    })}
                />
                <>
                    {!isLoading && ButtonIcon && iconPosition === "left" && <ButtonIcon {...getIconProps(type, fill, size)} />}
                    <span>{props.children}</span>
                    {!isLoading && ButtonIcon && iconPosition === "right" && <ButtonIcon {...getIconProps(type, fill, size)} />}
                </>
            </DropdownChevron>
            <DropdownCommon.Content.WithActions actions={actions} />
        </DropdownAlias>
    );
}

export namespace Buttons {
    export function Save(props: ButtonProps) {
        const lang = useLang();
        return <Button {...props} data-id="btn-save" htmlType="submit" children={lang.shared.save} />;
    }

    export function Next({ children, ...props }: ButtonProps) {
        const lang = useLang();
        return <Button {...props} data-id="btn-next" htmlType="submit" children={children || lang.shared.next} />;
    }

    export function Previous({ children, ...props }: ButtonProps) {
        const lang = useLang();
        return <Button {...props} data-id="btn-previous" type="default" children={children || lang.shared.previous} />;
    }

    export function Cancel(props: ButtonProps) {
        const lang = useLang();
        return <Button {...props} data-id="btn-cancel" type="default" children={lang.shared.cancel} />;
    }

    export function Icon(props: Omit<ButtonProps, "children">) {
        return <Button {...props} />;
    }

    export function Details({
        isLoading,
        onClick,
        icon,
        label
    }: Omit<ButtonProps, "icon"> & Required<Pick<ButtonProps, "icon">> & { label?: string; alwaysDisplay?: boolean }) {
        const lang = useLang();
        return (
            <Hoverable
                buttonProps={{
                    onClick,
                    icon,
                    isLoading
                }}
                label={label || lang.shared.details}
                alwaysDisplay
            />
        );
    }

    export function Dropdown({ "data-id": dataId, ...props }: { actions: DropdownActionProps[] } & ButtonProps) {
        return (
            <div data-id={dataId} className="flex h-max">
                {props.onClick ? <ClickableDropdownButton {...props} /> : <DropdownButton {...props} />}
            </div>
        );
    }

    export function Filters<T extends string = string>({
        label,
        filters,
        hasSearch = true
    }: {
        label: string;
        filters: Filter<T>[];
        hasSearch?: boolean;
    }): JSX.Element {
        const [isOpen, setIsOpen] = useState(false);
        const optionsCount = filters.flatMap(filter => filter.options).length;
        const selectedOptionsCount = filters.flatMap(filter => filter.defaultOptions).length;

        return (
            <DropdownAlias onOpenChange={setIsOpen} open={isOpen}>
                <DropdownCommon.Trigger.Button
                    type="default"
                    fill="solid"
                    size="md"
                    isDisabled={false}
                    icon={ChevronDownIcon}
                    isLoading={false}
                    children={
                        <div className="flex gap-2 truncate">
                            <div className="truncate">{label}</div>
                            {0 < selectedOptionsCount && selectedOptionsCount < optionsCount && <Tag type="info" size="sm" message={selectedOptionsCount} />}
                        </div>
                    }
                    iconPosition="right"
                />
                <DropdownCommon.Content.WithFilters filters={filters} onClose={() => setIsOpen(false)} hasSearch={hasSearch} />
            </DropdownAlias>
        );
    }

    export function Sort<T>({
        sorts,
        onSubmit,
        defaultSort
    }: {
        sorts: SortProps<T>[];
        defaultSort: Record<string, SortDirection | false> | undefined;
    } & Pick<ComponentProps<typeof DropdownCommon.Content.WithSort<T>>, "onSubmit">): JSX.Element {
        const lang = useLang();
        const [isOpen, setIsOpen] = useState(false);

        return (
            <DropdownAlias onOpenChange={setIsOpen} open={isOpen}>
                <DropdownCommon.Trigger.Button
                    type="default"
                    fill="solid"
                    size="md"
                    isDisabled={false}
                    icon={ChevronDownIcon}
                    isLoading={false}
                    children={
                        <div className="flex gap-2">
                            <div>{lang.shared.sortBy("")}</div>
                        </div>
                    }
                    iconPosition="right"
                />
                <DropdownCommon.Content.WithSort
                    data-id="dropdown-with-sort-content"
                    sorts={sorts}
                    onSubmit={onSubmit}
                    defaultSort={defaultSort}
                    onClose={() => setIsOpen(false)}
                />
            </DropdownAlias>
        );
    }
}
