import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import { motion } from "framer-motion";
import { isEqual, uniq, uniqBy } from "lodash";
import { ElementType, ReactNode, useMemo, useState } from "react";
import { useLang } from "../../../lang";
import { SortDirection } from "../../../services";
import { ROTATE_OPEN_VARIANT } from "../../referentials";
import { Buttons } from "../Button";
import { Button as ButtonAlias, ButtonProps } from "../Button/Button";
import { ChevronDownIcon } from "../Icons";
import { Input } from "../Input/Input";
import { Label as LabelAlias } from "../Input/Label";
import { Tooltip } from "../Tooltip/Tooltip";
import { DropdownActionProps } from "./Dropdown";

type Value<T> = T;
type ValueAndLabel<T> = {
    value: Value<T>;
    label: string;
};

export type Filter<T extends string = string> = {
    key: string;
    options: ValueAndLabel<T>[];
    label?: string;
    defaultOptions?: Value<T>[];
    onSubmit?: (selectedOptions: Value<T>[]) => unknown;
    disabled?: boolean;
};

export type FilterProps<T> = {
    key: string;
    filters: Filter[];
    label: string;
    onFilter?: (item: T, selectedOptions: string[]) => T | null;
    hasSearch?: boolean;
};

export type SortProps<T> = {
    key: string;
    label: string;
    onSort?: (a: T, b: T, direction?: SortDirection | false) => number;
    direction?: SortDirection;
    directionLabel?: Record<SortDirection, string>;
};

export namespace DropdownCommon {
    type DropdownTriggerProps = Omit<DropdownMenu.DropdownMenuTriggerProps, "asChild">;

    export function Trigger({ children, ...props }: DropdownTriggerProps) {
        return <DropdownMenu.Trigger asChild children={<div className="cursor-pointer truncate">{children}</div>} {...props} />;
    }

    export namespace Trigger {
        export function WithChevron({
            isOpen,
            children,
            "data-id": dataId,
            isDark
        }: {
            isOpen: boolean;
            children: ReactNode;
            "data-id"?: string;
            isDark?: boolean;
        }) {
            return (
                <Trigger
                    children={
                        <div
                            data-id={dataId}
                            className={classNames("flex max-w-[225px] cursor-pointer gap-2 rounded p-2 sm:min-w-[212px] sm:max-w-full", {
                                "hover:bg-grey-extra-light": !isDark
                            })}
                        >
                            <div className="flex-1 truncate">{children}</div>
                            <motion.div
                                className="flex items-center text-lg"
                                initial={isOpen ? "open" : "closed"}
                                animate={isOpen ? "open" : "closed"}
                                variants={ROTATE_OPEN_VARIANT}
                            >
                                <ChevronDownIcon size="sm" color={isDark ? "white" : "slate"} />
                            </motion.div>
                        </div>
                    }
                />
            );
        }

        export function Button(props: ButtonProps) {
            return <Trigger children={<ButtonAlias {...props} type={props.type || "default"} />} disabled={props.isDisabled || props.isLoading} />;
        }

        export function MenuIcon({ icon: Icon, "data-id": dataId, title }: { icon: ElementType; "data-id"?: string; title: string }) {
            const menuIcon = (
                <div
                    data-id={dataId}
                    className="flex h-9 w-9 items-center justify-center rounded border border-blue-extra-light bg-blue-extra-light p-2 transition-colors hover:border-slate-light"
                    children={<Icon color="slate" shade="light" />}
                />
            );
            return <Trigger>{title ? <Tooltip title={title} children={menuIcon} delayDuration="long" /> : menuIcon}</Trigger>;
        }
    }

    export type DropdownContentProps = {
        "data-id"?: string;
        header?: ReactNode;
        footer?: ReactNode;
    };

    export function Content({
        header,
        footer,
        children,
        "data-id": dataId,
        align = "end",
        sideOffset = 5,
        ...props
    }: Omit<DropdownMenu.DropdownMenuContentProps, "className"> & DropdownContentProps) {
        return (
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="z-50 overflow-auto rounded-md bg-white drop-shadow-lg" align={align} sideOffset={sideOffset} {...props}>
                    {header && <Label children={header} data-id={dataId ? `${dataId}-header` : "header"} />}
                    <DropdownMenu.Group {...(header && { className: "shadow-inner" })} children={children} />
                    <DropdownMenu.Arrow className="fill-white" />
                    {footer && <div children={footer} className="flex justify-end gap-2 border-t border-t-grey-extra-light p-4" />}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        );
    }

    export type DropdownContentActionsProps = {
        actions: DropdownActionProps[];
    } & DropdownContentProps;

    export namespace Content {
        export function WithActions({
            "data-id": dataId,
            actions,
            ...props
        }: Omit<DropdownMenu.DropdownMenuContentProps, "className" | "children"> & DropdownContentActionsProps) {
            return (
                <Content
                    data-id={dataId}
                    children={actions.map((action, i) => [
                        i > 0 && <Separator key={`${action.label}-separator`} />,
                        <Action
                            key={action.label}
                            {...action}
                            {...(action["data-id"] && { "data-id": dataId ? `${dataId}-${action["data-id"]}` : action["data-id"] })}
                            onClick={e => {
                                e.stopPropagation();
                                action.onClick?.(e);
                            }}
                        />
                    ])}
                    {...props}
                    onClick={e => e.stopPropagation()}
                />
            );
        }

        export function WithFilters<T extends string = string>({
            filters,
            onClose,
            hasSearch = true
        }: {
            filters: Filter<T>[];
            onClose: () => void;
            hasSearch?: boolean;
        }): JSX.Element {
            const lang = useLang();
            const [filteredList, setFilteredList] = useState<T[][]>(filters.map(filter => filter.defaultOptions ?? []));
            const [search, setSearch] = useState<string>("");

            const filteredListWithSearch = useMemo(() => {
                const sanitizedSearch = search.trim().toLowerCase();
                if (sanitizedSearch) {
                    return filters.map(group => ({
                        label: group.label,
                        options: group.options.filter(
                            item => item.value?.toLowerCase().includes(sanitizedSearch) || item.label.toLowerCase().includes(sanitizedSearch)
                        )
                    }));
                }
                return filters;
            }, [filters, search]);

            function doApply(): void {
                for (const [index, filter] of filters.entries()) {
                    filter.onSubmit?.(filteredList[index] ?? []);
                }
                onClose();
                setSearch("");
            }

            function doCancel(): void {
                setFilteredList(filters.map(filter => filter.defaultOptions ?? []));
                onClose();
                setSearch("");
            }

            function onFilter(item: T, isActive: boolean, index: number): void {
                setFilteredList(prev => {
                    if (isActive) {
                        return prev.map((filter, i) => (i === index ? uniq([...filter, item]) : filter));
                    } else {
                        return prev.map((filter, i) => (i === index ? filter.filter(option => option !== item) : filter));
                    }
                });
            }

            function toggleGroupInList(list: T[][], checked: boolean, index: number): typeof list {
                const group = filteredListWithSearch[index];
                if (!group) {
                    return list;
                }
                const values = group.options.map(option => option.value);
                if (checked) {
                    return filters.map((_, i) => {
                        const l = list[i] ?? [];
                        if (i === index) {
                            return uniq([...l, ...values]);
                        }
                        return l;
                    });
                }
                return filters.map((_, i) => {
                    const l = list[i] ?? [];
                    if (i === index) {
                        return l.filter(value => !values.includes(value));
                    }
                    return l;
                });
            }

            function onSelectGroup(checked: boolean, index: number): void {
                setFilteredList(prev => toggleGroupInList(prev, checked, index));
            }

            return (
                <DropdownCommon.Content
                    align="start"
                    onCloseAutoFocus={doCancel}
                    footer={
                        <>
                            <Buttons.Cancel size="sm" onClick={doCancel} isLoading={false} />
                            <Buttons.Save
                                size="sm"
                                onClick={doApply}
                                isLoading={false}
                                children={lang.filtering.apply}
                                isDisabled={isEqual(
                                    filteredList.flatMap(filter => filter).toSorted((a, b) => (a ?? "").localeCompare(b ?? "")),
                                    filters.flatMap(filter => filter.defaultOptions ?? []).toSorted((a, b) => (a ?? "").localeCompare(b ?? ""))
                                )}
                            />
                        </>
                    }
                >
                    <div className="w-72 space-y-4 p-4">
                        {hasSearch && (
                            <Input.Search
                                value={search}
                                onChange={event => setSearch(event.target.value)}
                                label={lang.filtering.filter}
                                placeholder={lang.filtering.search}
                            />
                        )}
                        {filteredListWithSearch.map((group, index) => {
                            if (group.options.length === 0) {
                                // Do not filter out empty groups before to ensure index consistency with filters & filteredList
                                return null;
                            }
                            const label = group.label?.trim();
                            const key = label || String(index);
                            const checkedOptions = group.options.filter(option => filteredList[index]?.includes(option.value));
                            return (
                                <div key={key}>
                                    {group.options.length <= 1 && group.label && <LabelAlias children={group.label} />}
                                    {group.options.length > 1 && (
                                        <Input.Checkbox
                                            id={key}
                                            checked={checkedOptions.length === group.options.length}
                                            disabled={filters[index]?.disabled}
                                            onChange={event => onSelectGroup(event.target.checked, index)}
                                            isIndeterminate={0 < checkedOptions.length && checkedOptions.length < group.options.length}
                                            label={label || lang.filtering.selectAll}
                                            labelFontSize="semibold"
                                        />
                                    )}
                                    <div className="max-h-32 overflow-auto rounded-md border border-grey-extra-light p-2">
                                        {uniqBy(group.options, "value").map(item => (
                                            <Input.Checkbox
                                                key={item.value}
                                                id={item.value}
                                                onChange={event => onFilter(item.value, event.target.checked, index)}
                                                checked={filteredList[index]?.includes(item.value)}
                                                disabled={filters[index]?.disabled}
                                                label={item.label}
                                            />
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </DropdownCommon.Content>
            );
        }

        export function WithSort<T>({
            sorts,
            onSubmit,
            onClose,
            defaultSort = {}
        }: {
            sorts: SortProps<T>[];
            onSubmit: (updatedSort: Record<string, SortDirection | false>) => void;
            onClose: () => void;
            defaultSort?: Record<string, SortDirection | false>;
        }): JSX.Element {
            const lang = useLang();
            const [selectedSort, setSelectedSort] = useState<Record<string, SortDirection | false>>(defaultSort);

            function doApply(): void {
                onSubmit(selectedSort);
                onClose();
            }

            function doClear(): void {
                setSelectedSort({});
            }

            return (
                <DropdownCommon.Content
                    align="start"
                    onCloseAutoFocus={onClose}
                    footer={
                        <>
                            <Buttons.Cancel size="sm" onClick={onClose} isLoading={false} />
                            <Buttons.Save size="sm" onClick={doApply} isLoading={false} children={lang.filtering.apply} />
                        </>
                    }
                >
                    <div className="w-72 space-y-4 p-4 text-slate-dark">
                        <div className="flex flex-col gap-2">
                            {sorts.map(sort => (
                                <div key={sort.key}>
                                    <LabelAlias children={sort.label} />
                                    <div className="max-h-32 overflow-auto rounded-md border border-grey-extra-light p-2">
                                        <Input.Radio
                                            id={`${sort.key}${SortDirection.ASCENDING}`}
                                            checked={selectedSort?.[sort.key] === SortDirection.ASCENDING}
                                            onChange={() => setSelectedSort({ [sort.key]: SortDirection.ASCENDING })}
                                            label={sort?.directionLabel?.asc ?? lang.shared.ascending}
                                        />
                                        <Input.Radio
                                            id={`${sort.key}${SortDirection.DESCENDING}`}
                                            checked={selectedSort?.[sort.key] === SortDirection.DESCENDING}
                                            onChange={() => setSelectedSort({ [sort.key]: SortDirection.DESCENDING })}
                                            label={sort?.directionLabel?.desc ?? lang.shared.descending}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button onClick={doClear} className="underline" children={lang.filtering.clearAll} />
                    </div>
                </DropdownCommon.Content>
            );
        }
    }

    type DropdownLabelProps = Omit<DropdownMenu.DropdownMenuLabelProps, "className">;

    export function Label(props: DropdownLabelProps) {
        return <DropdownMenu.Label className="p-4 text-sm font-light text-slate-primary" {...props} />;
    }

    export function Action({ href, icon: Icon, label, onClick, isDisabled, ...props }: DropdownActionProps) {
        const actionRender = (
            <DropdownMenu.Item
                className={classNames("select-none p-4 text-sm font-light outline-none", {
                    "pointer-none text-grey-light": isDisabled,
                    "cursor-pointer text-slate-primary hover:bg-grey-extra-light": !isDisabled
                })}
                children={
                    Icon ? (
                        <div className="flex items-center gap-2">
                            <Icon color="slate" size="xs" />
                            <div>{label}</div>
                        </div>
                    ) : (
                        label
                    )
                }
                disabled={isDisabled}
                {...(!isDisabled && { onClick })}
                {...props}
            />
        );
        return href && !isDisabled ? <a href={href}>{actionRender}</a> : actionRender;
    }

    export function Separator() {
        return <DropdownMenu.Separator className="border-t border-grey-extra-light" />;
    }
}
