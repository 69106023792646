import * as Switcher from "@radix-ui/react-switch";
import classNames from "classnames";
import { Size } from "../../referentials";
import { Label } from "./Label";

type SwitchComponentProps = {
    isChecked: boolean;
    onClick: () => void;
    labelSize?: Extract<Size, "sm" | "md">;
} & (
    | {
          label: React.ReactNode;
          id: string;
      }
    | {
          label?: never;
          id?: never;
      }
);

export default function Switch({ id, isChecked, onClick, label, labelSize = "md" }: SwitchComponentProps): JSX.Element {
    return (
        <div
            className={classNames({
                "flex items-center gap-2": label
            })}
        >
            <Switcher.Root
                id={id}
                className={classNames("h-6 w-10 rounded-full", {
                    "bg-slate-primary": isChecked,
                    "bg-grey-light": !isChecked
                })}
                onClick={onClick}
            >
                <Switcher.Thumb
                    className={classNames("block h-5 w-5 rounded-full bg-white duration-100", {
                        "translate-x-1": !isChecked,
                        "translate-x-4": isChecked
                    })}
                />
            </Switcher.Root>
            {label && <Label size={labelSize} children={label} htmlFor={id} />}
        </div>
    );
}
