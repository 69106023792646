import classNames from "classnames";
import { Size } from "../../referentials";

export function Label({
    size = "xs",
    fontSize = "semibold",
    children,
    disabled,
    required,
    htmlFor,
}: {
    size?: Extract<Size, "xs" | "sm" | "md">;
    fontSize?: "semibold" | "normal";
    children?: React.ReactNode;
    disabled?: boolean;
    required?: boolean;
    htmlFor?: string;
}) {
    return (
        <label
            className={classNames(`text-${size} font-${fontSize} truncate select-none`, {
                "text-grey-primary cursor-not-allowed": disabled,
                "text-slate-dark cursor-pointer": !disabled,
                "cursor-pointer": !disabled && htmlFor,
                "after:ml-1 after:text-danger after:content-['*']": required
            })}
            children={children}
            htmlFor={htmlFor}
        />
    );
}
